import { isNullOrWhitespace } from "../utils/validators";

export const professional = 'imonitor-qms-professional';
export const standard = 'imonitor-qms-standard';

const subscriptionPricingPoints = {
    [standard]: 'imonitor-qms-standard-USD-Monthly',
    [professional]: 'imonitor-qms-professional-USD-Monthly',
}

const validSubscriptionPlans = [professional, standard];
export const isValidSubscriptionPlan = (subscriptionPlan) => {
    if (isNullOrWhitespace(subscriptionPlan)) {
        return false;
    }

    return validSubscriptionPlans.indexOf(subscriptionPlan) > -1;
}


export const getSubscriptionPlanPricingPoint = (subscriptionPlanId) => {
    return subscriptionPricingPoints[subscriptionPlanId];
}