import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import StepUserDetails from './StepUserDetails';
import StepAccountDetails from './StepAccountDetails';
import ProgressIndicator from '../ProgressIndicator';
import StepPaymentDetails from './StepPaymentDetails';
import MarketingPanel from '../MarketingPanel';
import { useParams } from 'react-router-dom';
import { isValidSubscriptionPlan, standard, professional } from '../../constants/subscriptionPlans';
import logo from './logo-dark-svg.svg';
import { hotjar } from 'react-hotjar';
import * as hotjarConfig from '../../constants/hotjarConfig';

const Chargebee = window.Chargebee;

let chargbeeSite = 'imonitor-test';
if (process.env.REACT_APP_BUILD_ENV === 'prod') {
  chargbeeSite = 'imonitor';
  hotjar.initialize(hotjarConfig.hjid_prod, hotjarConfig.hjsv_prod);
}
else
{
  hotjar.initialize(hotjarConfig.hjid_dev, hotjarConfig.hjsv_dev);
}

function SignupForm({ sessionId }) {
  console.group('signup-form');

  useEffect(() => {
    console.group("chargbee-init");
    console.debug('Initialising Chargebee...');
    // eslint-disable-next-line no-unused-vars
    const chargebeeInstance = Chargebee.init({
      site: chargbeeSite,
      enableGTMTracking: true
    });
    console.debug('Done');
    console.groupEnd("chargbee-init");
  }, []);


  let { subscriptionPlanId } = useParams();
  if (!isValidSubscriptionPlan(subscriptionPlanId)) {
    console.debug('Invalid subscription detected. Defaulting to professional.')
    subscriptionPlanId = professional;
  }

  console.debug(`Active subscription plan: ${subscriptionPlanId}`);
  console.groupEnd('signup-form');


  const [signupState, setSignupState] = useState({
    currentStep: 0,
    userDetails: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    accountDetails: {
      accountName: '',
      companyName: '',
      regionName: '',
      siteName: ''
    }
  });

  const onStepComplete = (stepNo, values) => {
    setStepValues(stepNo, values);
  };

  const onBackClicked = (stepNo, values) => {
    const update = { ...signupState };
    switch (stepNo) {
      case 1:
        update.userDetails = values;
        break;

      case 2:
        update.accountDetails = values;
        break;

      default:
        break;
    }

    setSignupState({
      ...update,
      currentStep: signupState.currentStep - 1
    });

    // console.log({
    //   ...update,
    //   currentStep: signupState.currentStep - 1
    // })
  }


  const setStepValues = (stepNo, values) => {
    switch (stepNo) {
      case 1:
        setSignupState({
          ...signupState,
          currentStep: stepNo,
          userDetails: values
        });
        break;

      case 2:
        setSignupState({
          ...signupState,
          currentStep: stepNo,
          accountDetails: values
        });
        break;

      default:
        break;
    }
  }
  const RenderLogo = ()=>{
    return (
        <Logo>
            <img src={logo} alt="iMonitor"></img>
        </Logo>
    )   
}

  return (
    <MainContainer>
      <Title>
      </Title>
      <Body>
        <LeftBody>
          <SignupFormContainer>
            {RenderLogo()}
            <FormHeader>
              Sign up for a 14-day FREE trial
            </FormHeader>
            <ProgressIndicator currentStep={signupState.currentStep} />
            {signupState.currentStep === 0 && <StepUserDetails initialState={signupState.userDetails} onStepComplete={onStepComplete} />}
            {signupState.currentStep === 1 && <StepAccountDetails
              sessionId={sessionId}
              initialState={signupState.accountDetails}
              onStepComplete={onStepComplete}
              onBackClicked={onBackClicked} />}
            {
              signupState.currentStep === 2 &&
              <StepPaymentDetails
                sessionId={sessionId}
                onBackClicked={onBackClicked}
                subscriptionPlanId={subscriptionPlanId}
                accountDetails={signupState.accountDetails}
                userDetails={signupState.userDetails} />
            }
          </SignupFormContainer>
        </LeftBody>
        
        <RightBody>
          <RightPanel >
            <MarketingPanel currentStep={signupState.currentStep}></MarketingPanel>
          </RightPanel>
        </RightBody>
      </Body>
    </MainContainer>
  )
}

// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.div`
  width: 80vw;
  font-size: 1.5em;
  text-align: flex-start;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Body = styled.div`
  width: 90vw;
  font-size: 1.5em;
  text-align: flex-start;
  padding: 1em;
  display: flex;
  justify-content:center;
  align-items: flex-start;
`;
const LeftBody = styled.div`
  width: 60vw;
  height:auto; 
  align-items: center;
  display:inline;
  justify-content:center;
  @media (min-width: 1024px) {
    float:right; 
  }
  @media (max-width: 1024px) {
    width: 70vw;
    float:center; 
  }
  @media (max-width: 768px) {
    width: 80vw;
    float:center; 
  }
  @media (max-width: 600px) {
    width: 90vw;
    float:center; 
  }
`;
const RightBody = styled.div`
  width: 40vw;
  height:auto; 
  float:left; 
  display:inline;
  @media only screen and (max-width:1024px){
    display: none;
  }
`;
// Create a Wrapper component that'll render a <section> tag with some styles
const MainContainer = styled.section`
  background: #E9ECEF;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.5em;
`;

const SignupFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0000000D;
  border-radius: 5px;
  align-self: center;
  padding: 15px;
  margin: 15px;
  @media (min-width: 1024px) {
    float:right; 
  }
  @media (min-width: 1024px) {
    width: 600px;
    padding: 30px;
  }
  @media (min-width: 768px) {
    width: 500px;
    padding: 30px;
  }
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0000000D;
  border-radius: 5px;
  align-self: center;
  padding: 15px;
  margin: 15px;
  min-width: 250px;
  @media (min-width: 768px) {
    width: 150px;
    padding: 30px;
  }
`;

const FormHeader = styled.div`
  text-align: center;
  font: normal normal bold 20px/72px Roboto;
  letter-spacing: 0px;
  color: #2FB34D;
  text-transform: uppercase;
  opacity: 1;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 768px) {
    font: normal normal bold 16px/72px Roboto;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Logo = styled.div`
    height: 35px;
    padding-top:20px;
    padding-bottom:20px;
    display: flex;
    justify-content: center;
`;


// const FormLabel = styled.div`
//   font-size: 16px;
//   font-weight: bold;
//   margin-top: 15px;
// `
// const FormInput = styled.input`
//   background: #FFFFFF 0% 0% no-repeat padding-box;
//   border: 1px solid #CED4DA;
//   font-size: 16px;
//   padding: 10px;
//   margin-top: 10px;
//   margin-bottom: 10px;
//   border-radius: 5px;
// `

// const ValidationErrorMessage = styled.div`
//   color: red;
//   font-size: 12px;
// `;


export default SignupForm


